import * as React from 'react'
import { Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import { Helmet } from 'react-helmet'
import * as styles from '../../styles/insta-link/ami.module.css'

const Ami = () => {
  return (
    <div className={styles.amiContainer}>
      <Helmet title="Ami Kawanishi">
        <html lang="ja"/>
        <meta name="robots" content="index"/>
        <link rel="canonical" href="https://neutral-cc.com/insta-link/ami"/>
        <meta name="description" content="Ami Kawanishi（川西亜望）1982年、名古屋生まれ。Webデザイン/動画編集/イラスト/カフェ・雑貨店経営/買い付け/編み物講師/YouTuber"/>
        <meta name="image" content="https://neutral-cc.com/static/26afc48a7153fc65674697a1b2a17c79/0b55e/ami-kawanishi.png"/>
        <meta property="og:locale" content="ja_JP"/>
        <meta property="og:site_name" content="Ami Kawanishi"/>
        <meta property="og:url" content="https://neutral-cc.com/insta-link/ami"/>
        <meta property="og:title" content="Ami Kawanishi"/>
        <meta property="og:description" content="Ami Kawanishi（川西亜望）1982年、名古屋生まれ。Webデザイン/動画編集/イラスト/カフェ・雑貨店経営/買い付け/編み物講師/YouTuber"/>
        <meta property="og:image" content="https://neutral-cc.com/static/26afc48a7153fc65674697a1b2a17c79/0b55e/ami-kawanishi.png"/>
      </Helmet>
      <div className={styles.amiInner}>
        <StaticImage
          className={styles.amiImage}
          alt="Ami Kawanishi"
          src="../../images/insta-link/ami/ami-kawanishi.png"
        />
        <h1 className={styles.amiName}>Ami Kawanishi</h1>
        <a href="https://www.instagram.com/amihuis_ami/" className={styles.amiMainLink} target="_blank" rel="noopener noreferrer">
          <StaticImage
            className={styles.amiSnsIcon}
            alt="Instagram"
            src="../../images/insta-link/ami/icon-instagram.svg"
          />
          amihuis_ami
        </a>
        <h1 className={styles.amiTitle}>Works</h1>
        <p className={styles.amiSubTitle}>
          Web Design / Coaching
        </p>
        <div className={styles.amiLinkWrapper}>
          <StaticImage
            className={styles.amiCushion}
            alt="@neutral_cc"
            src="../../images/insta-link/ami/cushion.png"
          />
          <a href="https://www.instagram.com/neutral_cc/" className={styles.amiLink} target="_blank" rel="noopener noreferrer">
            <StaticImage
              className={styles.amiSnsIcon}
              alt="Instagram"
              src="../../images/insta-link/ami/icon-instagram.svg"
            />
            neutral_cc
          </a>
        </div>
        <p className={styles.amiSubTitle}>
          Cafe owner
        </p>
        <div className={styles.amiLinkWrapper}>
          <StaticImage
            className={styles.amiCushion}
            style={{width: "160px"}}
            alt="@neutral_cc"
            src="../../images/insta-link/ami/cushion.png"
          />
          <a href="https://www.instagram.com/mond_nagoya/" className={styles.amiLink} target="_blank" rel="noopener noreferrer">
            <StaticImage
              className={styles.amiSnsIcon}
              alt="Instagram"
              src="../../images/insta-link/ami/icon-instagram.svg"
            />
            mond_nagoya
          </a>
        </div>
        <p className={styles.amiSubTitle}>
          Shop owner / Buyer
        </p>
        <div className={styles.amiLinkWrapper}>
          <StaticImage
            className={styles.amiCushion}
            style={{width: "160px"}}
            alt="@neutral_cc"
            src="../../images/insta-link/ami/cushion.png"
          />
          <a href="https://www.instagram.com/relier_vintage/" className={styles.amiLink} target="_blank" rel="noopener noreferrer">
            <StaticImage
              className={styles.amiSnsIcon}
              alt="Instagram"
              src="../../images/insta-link/ami/icon-instagram.svg"
            />
            relier_vintage
          </a>
        </div>
        <h1 className={styles.amiTitle}>Private</h1>
        <div className={styles.amiLinkWrapper}>
          <StaticImage
            className={styles.amiCushion}
            style={{width: "160px"}}
            alt="@neutral_cc"
            src="../../images/insta-link/ami/cushion.png"
          />
          <a href="https://twitter.com/amihuis_ami" className={styles.amiLink} target="_blank" rel="noopener noreferrer">
            <StaticImage
              className={styles.amiSnsIcon}
              alt="Instagram"
              src="../../images/insta-link/ami/icon-twitter.svg"
            />
            amihuis_ami
          </a>
        </div>
        <div className={styles.amiLinkWrapper}>
          <StaticImage
            className={styles.amiCushion}
            alt="@neutral_cc"
            src="../../images/insta-link/ami/cushion.png"
          />
          <a href="https://www.youtube.com/c/AMIHUIS" className={styles.amiLink} target="_blank" rel="noopener noreferrer">
            <StaticImage
              className={styles.amiSnsIcon}
              alt="Instagram"
              src="../../images/insta-link/ami/icon-youtube.svg"
            />
            AMIHUIS
          </a>
        </div>
        <Link to="/" className={styles.neutralLogoLink}>
          <StaticImage
            className={styles.neutralLogo}
            alt="neutral"
            src="../../images/insta-link/ami/logo-neutral.png"
          />
        </Link>
        <p className={styles.neutralText}>Created by NEUTRAL</p>
        <div className={styles.blobsWhiteUpper}>
          <StaticImage
            alt="Blobs white"
            src="../../images/insta-link/ami/blobs-white-upper.png"
          />
        </div>
        <div className={styles.blobsBlue}>
          <StaticImage
            alt="Blobs blue"
            src="../../images/insta-link/ami/blobs-blue.png"
          />
        </div>
        <div className={styles.blobsYellow}>
          <StaticImage
            alt="Blobs yellow"
            src="../../images/insta-link/ami/blobs-yellow.png"
          />
        </div>
        <div className={styles.blobsWhiteLower}>
          <StaticImage
            alt="Blobs white"
            src="../../images/insta-link/ami/blobs-white-lower.png"
          />
        </div>
      </div>
    </div>
  )
}

export default Ami
