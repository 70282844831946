// extracted by mini-css-extract-plugin
export var amiContainer = "ami-module--ami-container--yDhLU";
export var amiCushion = "ami-module--ami-cushion--Kmfb0";
export var amiImage = "ami-module--ami-image--UIcAI";
export var amiInner = "ami-module--ami-inner--yk0V8";
export var amiLink = "ami-module--ami-link--T2Bdy";
export var amiLinkWrapper = "ami-module--ami-link-wrapper--yjDLJ";
export var amiMainLink = "ami-module--ami-main-link--l1fFk";
export var amiName = "ami-module--ami-name--MepEX";
export var amiSnsIcon = "ami-module--ami-sns-icon--baoep";
export var amiSubTitle = "ami-module--ami-sub-title--mVKw7";
export var amiTitle = "ami-module--ami-title--scjCv";
export var blobsBlue = "ami-module--blobs-blue--CAQI6";
export var blobsWhiteLower = "ami-module--blobs-white-lower--ZFP3c";
export var blobsWhiteUpper = "ami-module--blobs-white-upper--OrPVw";
export var blobsYellow = "ami-module--blobs-yellow--X5WBZ";
export var neutralLogo = "ami-module--neutral-logo--e7hCK";
export var neutralLogoLink = "ami-module--neutral-logo-link--p-8Jq";
export var neutralText = "ami-module--neutral-text--43xC1";